const $sustainability = $('#sustainability');
let $content;
let $images;
let containerOffsetTop;
let containerWidth;
let containerHeight;
let contentHeight;
let windowHeight;
let windowScrollTop;

let $items;
let itemCount;
let itemBreakpoint;
let $progressBars;

let activeItem = 1;
let fixed = false;
let percentage = 0;
let min;
let max;

let interval;

const initSustainability = () => {
    if ($sustainability && $sustainability.length > 0) {
        $content = $sustainability.find('.row');
        $images = $('.sustainability__img');
        containerOffsetTop = $sustainability.offset().top;
        containerWidth = $sustainability.innerWidth();
        containerHeight = 0;
        contentHeight = $content.innerHeight();
        windowHeight = $(window).innerHeight();
        windowScrollTop = 0;

        $items = $sustainability.find('[data-item]');
        itemCount = $items.length;
        itemBreakpoint = 100 / itemCount;
        $progressBars = [];

        if (contentHeight < windowHeight) {
            handleImg();
            setContainerHeight();

            $items.each((index, element) => {
                $progressBars.push($(element).find('.progress-bar'));
            });

            $(window).on('resize', () => {
                containerOffsetTop = $sustainability.offset().top;
                containerWidth = $sustainability.innerWidth();
                contentHeight = $content.innerHeight();
                windowHeight = $(window).innerHeight();

                setContainerHeight();
            });

            $(window)[0].addEventListener('scroll', () => {
                windowScrollTop = $(window).scrollTop();

                setPercentage();
                setContentPosition();
                handleItems();
            }, { passive: true });

            interval = setInterval(() => {
                if ($('.interactive-house').height() > 50) {
                    containerOffsetTop = $sustainability.offset().top;
                    setContainerHeight();

                    clearInterval(interval);
                }
            }, 100);
        } else {
            destroy();

            $items.addClass('not-scrolling');
            $items.first().addClass('active--current');

            $items.each((index, item) => {
                $(item).on('click', (event) => {
                    $items.removeClass('active--current');
                    $images.removeClass('active');

                    $(event.currentTarget).addClass('active--current');
                    $($images[index]).addClass('active');
                });
            });
        }
    }
};

const setContainerHeight = () => {
    $sustainability.height(contentHeight * 3);

    containerHeight = $sustainability.innerHeight();
    min = containerOffsetTop - ((windowHeight - contentHeight) / 2); // 0%
    max = (containerOffsetTop + containerHeight) - ((windowHeight - contentHeight) / 2) - contentHeight; // 100%
};

const setPercentage = () => {
    percentage = ((windowScrollTop - min) * 100) / (max - min);
};

const setContentPosition = () => {
    if (!fixed && percentage >= 0) {
        fixed = true;

        $content.removeClass('bottom');
        $content.addClass('fixed');
        $content.css('width', containerWidth + 32);
    }

    if (fixed && percentage < 0) {
        fixed = false;

        $content.removeClass('bottom');
        $content.removeClass('fixed');
    }

    if (percentage > 100) {
        fixed = false;

        $content.addClass('bottom');
        $content.removeClass('fixed');
    }
};

const handleItems = () => {
    const itemPercentage = percentage / itemBreakpoint;
    const itemNum = Math.ceil(itemPercentage);

    if (itemNum >= 1 && itemNum <= itemCount && itemNum !== activeItem) {
        activeItem = itemNum;

        handleImg();
    }

    if (itemNum >= 0 && itemNum <= itemCount + 1) {
        $progressBars.forEach(($progressBar, index) => {
            if (itemNum - 1 < index) {
                $progressBars[index].css('width', '0');
                $($items[index]).removeClass('active');
                $($items[index]).removeClass('active--current');
            } else if (itemNum - 1 > index) {
                $progressBars[index].css('width', '100%');
                $($items[index]).addClass('active');
                $($items[index]).removeClass('active--current');
            } else {
                $progressBars[index].css('width', `${(itemPercentage - index) * 100}%`);
                $($items[index]).addClass('active');
                $($items[index]).addClass('active--current');
            }
        });
    }
};

const handleImg = () => {
    $images.removeClass('active');
    $($images[activeItem - 1]).addClass('active');
};

const destroy = () => {
    $items.each((index, item) => {
        $(item).addClass('active');
    });

    $sustainability.height('auto');
};

export default initSustainability;
