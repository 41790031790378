/* eslint-disable */
//  Developed @ Herrlich Media Console
export default function devAt() {
    const devAtString = [
        /* eslint-disable-next-line */
        "%c %c %c Developed @  %c  https://herrlich.media/","background: #697b90","background: #697b90","color: #89eec1; background: #697b90;","background: #ffffff"
    ];


    /* eslint-disable-next-line */
    console.log.apply(console, devAtString);
}
