const initHandleInputs = () => {
    $(document).on('focus', 'input[type="text"], input[type="email"], textarea', (el) => {
        const $el = $(el.target);
        const $label = $el.parent().siblings();

        $label.addClass('active');
    }).on('focusout', (el) => {
        const $el = $(el.target);
        const $label = $el.parent().siblings();

        if ($el.val().length === 0) {
            $label.removeClass('active');
        }
    });
};

export default initHandleInputs;
