const initHouse = () => {
    const house = document.querySelectorAll('.interactive-house');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const image = entry.target;

            if (entry.isIntersecting) {
                image.classList.add('active');
            } else {
                image.classList.remove('active');
            }
        });
    }, options);

    house.forEach((el) => {
        observer.observe(el);
    });
};

export default initHouse;
