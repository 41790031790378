/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// Navigation Hover Intend
(function($) {
  /* hoverIntent by Brian Cherne */
    $.fn.hoverIntent = function(f, g) {
    // default configuration options
        let cfg = {
            sensitivity: 7,
            interval: 100,
            timeout: 0
        };
    // override configuration options with user supplied object
        cfg = $.extend(cfg, g ? { over: f, out: g } : f);

    // instantiate variables
    // cX, cY = current X and Y position of mouse, updated by mousemove event
    // pX, pY = previous X and Y position of mouse, set by mouseover and polling interval
        let cX,
            cY,
            pX,
            pY;

    // A private function for getting mouse position
        const track = function(ev) {
            cX = ev.pageX;
            cY = ev.pageY;
        };

    // A private function for comparing current and previous mouse position
        const compare = function(ev, ob) {
            ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
      // compare mouse positions to see if they've crossed the threshold
            if ((Math.abs(pX - cX) + Math.abs(pY - cY)) < cfg.sensitivity) {
                $(ob).unbind('mousemove', track);
        // set hoverIntent state to true (so mouseOut can be called)
                ob.hoverIntent_s = 1;
                return cfg.over.apply(ob, [ev]);
            }
        // set previous coordinates for next time
            pX = cX; pY = cY;
        // use self-calling timeout, guarantees intervals are spaced out properly (avoids JavaScript timer bugs)
            ob.hoverIntent_t = setTimeout(() => { compare(ev, ob); }, cfg.interval);
        };

    // A private function for delaying the mouseOut function
        const delay = function(ev, ob) {
            ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
            ob.hoverIntent_s = 0;
            return cfg.out.apply(ob, [ev]);
        };

    // A private function for handling mouse 'hovering'
        const handleHover = function(hnd) {
      // next three lines copied from jQuery.hover, ignore children onMouseOver/onMouseOut
            let p = (hnd.type === 'mouseover' ? hnd.fromElement : hnd.toElement)
                || hnd.relatedTarget;
            while (p && p !== this) { try { p = p.parentNode; } catch (t) { p = this; } }
            if (p === this) { return false; }

      // copy objects to be passed into t (required for event object to be passed in IE)
            const ev = jQuery.extend({}, hnd);
            const ob = this;

      // cancel hoverIntent timer if it exists
            if (ob.hoverIntent_t) { ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t); }

      // else e.type == "onmouseover"
            if (hnd.type === 'mouseover') {
                // set "previous" X and Y position based on initial entry point
                pX = ev.pageX;
                pY = ev.pageY;
                // update "current" X and Y position based on mousemove
                $(ob).bind('mousemove', track);
                // start polling interval (self-calling timeout) to compare mouse coordinates over time
                if (ob.hoverIntent_s !== 1) {
                    ob.hoverIntent_t = setTimeout(() => {
                        compare(ev, ob);
                    }, cfg.interval);
                }

                // else e.type == "onmouseout"
            } else {
                // unbind expensive mousemove event
                $(ob).unbind('mousemove', track);
                // if hoverIntent state is true, then call the mouseOut function after the specified delay
                if (ob.hoverIntent_s === 1) {
                    ob.hoverIntent_t = setTimeout(() => {
                        delay(ev, ob);
                    }, cfg.timeout);
                }
            }
        };

    // bind the function to the two event listeners
        return this.mouseover(handleHover).mouseout(handleHover);
    };
})(jQuery);
