import '@fancyapps/fancybox';
import './plugins/inView';
import GLightbox from 'glightbox';
import Swiper, { Navigation, Pagination } from 'swiper';
import AOS from 'aos';

import initTooltip from './inc/hmTooltip';
import initSustainability from './inc/sustainability';
import initHouse from './inc/interactiveHouse';
import initHandleInputs from './inc/handleInputs';
import dataStore from './inc/dataStore';
import { hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination]);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    initTooltip();
    initSustainability();
    initHouse();
    initHandleInputs();

    window.showYoutube = () => {
        $('.videoRow').show();
    };

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    AOS.init({
        once: true,
    });

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();

        if (dataStore.docScroll > 0 && frontpagePopupTriggerFlag === false) {
            frontpagePopupTrigger();
        }
    });

    // Set VH, remove safari bar on ios
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Smooth Anchor Scroll
    document.querySelectorAll('a[href^="#"]:not(._brlbs-btn)').forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
            e.preventDefault();

            const href = e.target.getAttribute('href');
            const target = document.querySelector(href);

            if (href !== '#') {
                if (target) {
                    target.scrollIntoView({
                        behavior: 'smooth'
                    });
                } else {
                    window.location.href = window.location.origin + href;
                }
            }
        });
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    // mobileSubmenu();
    // herrlichesMenu.destroy();
    // herrlichesMenu.init();
}

$(window).on('load', () => {
    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    new Swiper('.headerSlider', {
        // Optional parameters
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    });

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.fadeIn').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).not('.vis').addClass('vis');
        }
    });

    $('.fadeInRow').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).find('> div, > li, > p').not('.vis').each((i, el) => {
                setTimeout(() => {
                    $(el).addClass('vis');
                }, 150 * i);
            });
        }
    });

    dataStore.lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        plyr: {
            config: {
                ratio: '16:9', // or '4:3'
                muted: false,
                hideControls: true,
                youtube: {
                    noCookie: true,
                    rel: 0,
                    showinfo: 0,
                    iv_load_policy: 3
                },
                vimeo: {
                    byline: false,
                    portrait: false,
                    title: false,
                    speed: true,
                    transparent: false
                }
            }
        }
    });
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

/*--- frontpage Popup Trigger ---*/
let frontpagePopupTriggerFlag = false;
function frontpagePopupTrigger() {
    frontpagePopupTriggerFlag = true;
    // const frontpagePopupTriggerTs = localStorage.getItem('frontpagePopupTriggerTs');
    const dateNow = new Date();
    // let hoursBetweenDates = 0;

    setTimeout(() => {
        // if (frontpagePopupTriggerTs !== null) {
        //     const msBetweenDates = Math.abs(frontpagePopupTriggerTs - dateNow.getTime());
        //     hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        // }

        // if (frontpagePopupTriggerTs === null || hoursBetweenDates > 24) {
        localStorage.setItem('frontpagePopupTriggerTs', dateNow.getTime());
        $('#frontpagePopup__trigger').trigger('click');
        // }
    }, 1000);
}
